import React from 'react'
import styled from 'styled-components'
import { navigate } from "gatsby"
import { Button } from "./Button"

// https://www.youtube.com/watch?v=T4IxIzhUUUs 
// How to build a Lightbox in Gatsby (w/ Gatsby Images & React Bootstrap)
// BE CAREFUL - MAI 2022
// Unfortunately simple-react-lightbox seems to no longer be maintained....
// not only is it no longer maintained, but they took down the whole repo.  hooray for open-source!
// https://react-bootstrap.github.io/layout/grid/
// https://getbootstrap.com/docs/4.0/utilities/spacing/
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const Gallery = () => {

  const data = useStaticQuery(graphql`
  query gallery {
    allFile(
      filter: {
      relativeDirectory: {eq: "GALERIE"}}
      sort: {fields: base, order: ASC}) {
      edges {
        node {
          id
          publicURL
          base
          childImageSharp {
            gatsbyImageData(
              height: 300
              width: 500
              transformOptions: {fit: COVER}
              placeholder: BLURRED
              webpOptions: {quality: 50}
            )
          }
        }
      }
    }
  }
  `
  )
  return (
    <GalleryContainer>
        <TopLine>
            Galerie
        </TopLine>
            {/* Bootstrap works off a 12-column => 12/4=3*/}
            {/* Bootstrap utility classe "py-1" */}
            <Container>
                <SimpleReactLightbox>
                    <SRLWrapper>
                      <Row>
                        {data.allFile.edges.map(({node}) => (
                          <Col lg={4} md={6} key={node.id} className="py-3">
                          <H5>
                          <h5>{node.base.split('-').join(' ').split('.')[0]}</h5>
                          </H5>
                          <a href={node.publicURL}>
                              <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0]} />
                          </a>
                          </Col>
                        ))}
                      </Row>
                    </SRLWrapper>
                </SimpleReactLightbox>
                <Button >
                  <a onClick={() => window.history.back()}>BACK</a>
                  </Button>
                <Custom>
                  <h3>Vous cherchez quelque chose de personnalisé?</h3>
                  <button onClick={()=>{navigate("/email")}}>Ecrivez-moi via le formulaire </button>
                </Custom>
            </Container>
            
    </GalleryContainer>
  )
}

export default Gallery

const GalleryContainer = styled.div`
  min-height: 100vh;
  padding: 0rem calc((100vw - 1300px) / 2);
  color: rgba(69, 73, 69, 0.636);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  )
  `
const H5 = styled.p`
  h5 {
    text-align: center;
    }
  `
const TopLine = styled.p`
  font-family: BetterGrade;  
  font-size: 10rem; 
  color : #b51111 ;
  color : #404640;
    text-align: center;
    margin-bottom: 0.75rem;
      @media screen and (max-width: 768px) {
        margin: 0px;
        padding: 0px;
        font-size: 5rem
      }
`
const Custom = styled.p`
text-align: center;
padding: 6rem;
    @media screen and (max-width: 768px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
h3 {
  font-family: BetterGrade;  
  padding-bottom: 1rem;
  color : white;
  font-size: 4rem;
  @media screen and (max-width: 768px) {

  }
  }
    button {
      padding: 25px 30px;
      margin-top: 0px;
      border-style: none;
      font-size: 19px;
      color: white;
      font-weight: bold;
      background-color: #404640;  
      &:hover {
        background: #ab9668  ;
        transform: translateY(-2px);
    }
  `
